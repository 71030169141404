<script lang="ts" setup>
import ServiceShowcase from '@/components/ServiceShowcase.vue';
import PartnerCompaniesSection from '@/components/PartnerCompaniesSection.vue';
import CustomerReviews from '@/components/CustomerReviews.vue';
import KeyFeaturesSection from '@/components/KeyFeaturesSection.vue';
import Footer from '@/components/Footer.vue';

import { onMounted, ref } from 'vue';

const navigation = [
  { name: 'Services', href: '#services' },
  { name: 'How it works', href: '#howitworks' },
  { name: 'Partners', href: '#partners' },
  { name: 'Reviews', href: '#reviews' },
  { name: 'Key features', href: '#features' },
];

const mobileMenuOpen = ref(false);

function openSocials(socialNetwork: string) {
  switch (socialNetwork) {
    case 'instagram':
      window.open('https://www.instagram.com/talab_uk', '_blank');
      break;
    case 'tiktok':
      window.open('https://www.tiktok.com/@talabuk', '_blank');
      break;
    case 'linkedin':
      window.open(' https://www.linkedin.com/company/talabuk', '_blank');
      break;
    default:
      window.open('https://x.com/talab_uk', '_blank');
      break;
  }
}

function scrollToTop() {
  window.scrollTo(0, 0);
}

onMounted(() => {
  const video: any = document.getElementById('video');
  if (video) video.play();

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('show');
      }
      // else entry.target.classList.remove('show');
    });
  });

  const hidenElements = document.querySelectorAll('.anim');
  hidenElements.forEach((el) => observer.observe(el));
});
</script>

<template>
  <div class="bg-white">
    <header class="absolute inset-x-0 top-0 z-50">
      <nav class="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div class="flex lg:flex-1">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">TALAB</span>
            <img class="h-5 w-auto" src="@/assets/gold-logo-transparent.png" alt="" />
          </a>
        </div>
        <div class="flex lg:hidden">
          <button
            type="button"
            class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            @click="mobileMenuOpen = true"
          >
            <span class="sr-only">Open main menu</span>
            <MenuAlt3Icon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="hidden lg:flex lg:gap-x-12">
          <a
            v-for="item in navigation"
            :key="item.name"
            :href="item.href"
            class="text-sm font-semibold leading-6 text-gray-900"
            >{{ item.name }}</a
          >
        </div>
        <div class="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="https://apps.apple.com/us/app/talab-ultimate-student-app/id6503147995" class="text-sm font-semibold leading-6 text-gray-900"
            >Download <span aria-hidden="true">&rarr;</span></a
          >
        </div>
      </nav>
      <Dialog class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
        <div class="fixed inset-0 z-50" />
        <DialogPanel
          class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
        >
          <div class="flex items-center justify-between">
            <a href="#" class="-m-1.5 p-1.5">
              <span class="sr-only">TALAB</span>
              <img
                class="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              />
            </a>
            <button
              type="button"
              class="-m-2.5 rounded-md p-2.5 text-gray-700"
              @click="mobileMenuOpen = false"
            >
              <span class="sr-only">Close menu</span>
              <XIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="mt-6 flow-root">
            <div class="-my-6 divide-y divide-gray-500/10">
              <div class="space-y-2 py-6">
                <a
                  v-for="item in navigation"
                  :key="item.name"
                  :href="item.href"
                  class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >{{ item.name }}</a
                >
              </div>
              <div class="py-6">
                <a
                  href="#"
                  class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >Log in</a
                >
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>

    <!-- HERO SECTION -->
    <div class="relative isolate px-6 pt-14 lg:px-8 anim slide-up">
      <div
        class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style="
            clip-path: polygon(
              74.1% 44.1%,
              100% 61.6%,
              97.5% 26.9%,
              85.5% 0.1%,
              80.7% 2%,
              72.5% 32.5%,
              60.2% 62.4%,
              52.4% 68.1%,
              47.5% 58.3%,
              45.2% 34.5%,
              27.5% 76.7%,
              0.1% 64.9%,
              17.9% 100%,
              27.6% 76.8%,
              76.1% 97.7%,
              74.1% 44.1%
            );
          "
        />
      </div>
      <div class="mx-auto max-w-4xl py-16 sm:py-24 lg:py-16">
        <div class="text-center">
          <h1 class="font-bold tracking-tight text-gray-900 text-6xl text-center md:text-7xl">
            Introducing Ultimate Student Concierge App
          </h1>
          <p class="mt-6 text-lg leading-8 text-gray-600">
            The app designed to make your student life in London effortless and enjoyable.
            Experience unparalleled convenience and premium services, all at your fingertips.
          </p>
          <div class="mt-10 flex flex-col md:flex-row gap-10 items-center justify-center gap-x-6">
            <a
              target="_blank"
              href="https://apps.apple.com/us/app/talab-ultimate-student-app/id6503147995"
              class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
            >
              <svg
                class="me-3 w-7 h-7"
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="apple"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path
                  fill="currentColor"
                  d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                ></path>
              </svg>
              <div class="text-left rtl:text-right">
                <div class="mb-1 text-xs">Download on the</div>
                <div class="-mt-1 font-sans text-sm font-semibold">App Store</div>
              </div>
            </a>
            <a href="#services" class="text-sm font-semibold leading-6 text-gray-900"
              >Read more <span aria-hidden="true">→</span></a
            >
          </div>
        </div>
      </div>
      <div
        class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style="
            clip-path: polygon(
              74.1% 44.1%,
              100% 61.6%,
              97.5% 26.9%,
              85.5% 0.1%,
              80.7% 2%,
              72.5% 32.5%,
              60.2% 62.4%,
              52.4% 68.1%,
              47.5% 58.3%,
              45.2% 34.5%,
              27.5% 76.7%,
              0.1% 64.9%,
              17.9% 100%,
              27.6% 76.8%,
              76.1% 97.7%,
              74.1% 44.1%
            );
          "
        />
      </div>
    </div>

    <!-- SECTION -->
    <div class="mt-24 px-6 lg:px-8 flex flex-col" id="services">
      <div class="border-[1px] border-[#000000] py-1 px-6 rounded-full text-nowrap whitespace-nowrap w-3/4 md:w-auto mb-5 mx-auto anim slide-up">
        <p class="text-[#000000] font-medium text-center">Featured Services</p>
      </div>
      <h1 class="font-bold leading-tight text-gray-900 text-5xl text-center anim slide-up mb-5">
        One App. Infinite <br />Service Possibilities.
      </h1>

      <div class="flex flex-col md:flex-row justify-center items-center gap-10">
        <section class="flex flex-col gap-16 anim slide-right delay-1">
          <ServiceShowcase service="cleaning" />
          <ServiceShowcase service="rental" />
          <ServiceShowcase service="university" />
        </section>
        <video id="video" class="h-[85vh] anim zoom-in" muted autoplay loop playsinline>
          <source src="@/assets/app-showcase-mockup.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <section class="flex flex-col gap-16 anim slide-left delay-1">
          <ServiceShowcase service="booking" reverse />
          <ServiceShowcase service="chauffeur" reverse />
          <ServiceShowcase service="personal" reverse />
        </section>
      </div>
    </div>
  </div>
  <div class="bg-[#f9fafb] flex flex-col items-center mt-5 py-20" id="howitworks">
    <div class="border-[1px] border-[#000000] py-1 px-6 rounded-full anim slide-up">
      <p class="text-[#000000] font-medium">How It Works</p>
    </div>

    <h1 class="font-bold leading-tight text-gray-900 text-5xl text-center anim slide-up my-5 anim slide-up">
      Experience The Convenience.
    </h1>

    <div class="flex justify-center w-screen gap-5 md:gap-20 px-20 anim zoom-in">
      <img
        src="@/assets/talab-explore-mockup.webp"
        alt=""
        class="w-[45%] md:w-[25%] hover:scale-95 transition-all"
      />

      <img
        src="@/assets/talab-service-mockup.webp"
        alt=""
        class="w-[45%] md:w-[25%] hover:scale-95 transition-all"
      />

      <img
        src="@/assets/talab-chat-mockup.webp"
        alt=""
        class="w-[45%] md:w-[25%] hover:scale-95 transition-all"
      />
    </div>
  </div>
  <PartnerCompaniesSection id="partners"/>

  <CustomerReviews id="reviews"/>

  <KeyFeaturesSection id="features"/>

  <Footer />
</template>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

html,
body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-family: "Inter";
}

.gradient-text {
  background: linear-gradient(85deg, #bc8809, #dbcc8b);
  color: #bc8809;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
</style>
