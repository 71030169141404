<template>
  <footer class="bg-[#f9fafb] mt-10">
    <div class="w-full mx-auto p-4 pt-10 md:pt-4 md:py-8 ">
      <div class="flex flex-col items-center md:flex-row md:justify-between">
        <a
          href="https://talabapp.co.uk"
          class="flex items-center mb-10 sm:mb-0"
        >
          <img src="@/assets/gold-logo-transparent.png" class="h-8" alt="Flowbite Logo" />
        </a>
        <ul class="flex flex-col gap-3 md:gap-0 md:flex-row flex-wrap items-center mb-6 text-sm font-medium text-gray-500">
          <li v-for="item in navigation" :key="item.name">
            <a :href="item.href" class="hover:underline me-0 md:me-6 text-center md:text-left">{{ item.name }}</a>
          </li>
          <li>
            <a href="/privacy-policy" class="hover:underline me-0 md:me-6">Privacy Policy</a>
          </li>
        </ul>
      </div>
      <hr class="my-6 border-gray-200 sm:mx-auto lg:my-8" />
      <span class="block text-sm text-gray-500 text-center"
        >© 2024 <a href="https://talabapp.co.uk" class="hover:underline">TALAB</a>&trade;. All Rights
        Reserved.</span
      >
    </div>
  </footer>
</template>
<script setup lang="ts">
const navigation = [
  { name: 'Services', href: '#services' },
  { name: 'How it works', href: '#howitworks' },
  { name: 'Partners', href: '#partners' },
  { name: 'Reviews', href: '#reviews' },
  { name: 'Key features', href: '#features' },
];
</script>
