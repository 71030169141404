<template>
  <div class="flex flex-col md:flex-row py-24 px-6 lg:px-20 gap-4 items-start">
    <div class="w-full md:w-1/2 flex flex-col items-center md:items-start mt-8 anim slide-right">
      <div class="border-[1px] border-[#000000] py-1 px-6 rounded-full text-nowrap whitespace-nowrap w-3/4 md:w-auto mb-5">
        <p class="text-[#000000] text-center font-medium">Simplify Your Student Life</p>
      </div>

      <h2 class="font-semibold tracking-normal text-center md:text-left leading-tight text-gray-900 text-5xl md:text-6xl">
        Clear, concise and time saving.
      </h2>
      <p class="mt-4 text-center md:text-left text-lg text-gray-600">TALAB is here to streamline your daily tasks and enhance your student life in London. From personal assistance to reliable services.</p>

      <div class="flex items-center gap-3 mt-12">
        <img src="@/assets/check-mark.png" class="size-5" alt="Check Mark Icon">
        <p class="text-[16px] text-gray-600">Personal Assistant Support</p>
      </div>
      <div class="flex items-center gap-3 mt-5">
        <img src="@/assets/check-mark.png" class="size-5" alt="Check Mark Icon">
        <p class="text-[16px] text-gray-600">Reliable Service Providers</p>
      </div>
      <div class="flex items-center gap-3 mt-5">
        <img src="@/assets/check-mark.png" class="size-5" alt="Check Mark Icon">
        <p class="text-[16px] text-gray-600">Student-Affordable Prices</p>
      </div>
    </div>
    <div class="flex mt-4 md:mt-0 justify-center w-full md:w-1/2 anim slide-left">
      <img
        src="@/assets/welcome-screen-mockup.webp"
        alt="TALAB - Personal Assistant"
        class="w-[40%]"
      />
      <img
        src="@/assets/mockaup.webp"
        alt="TALAB - Personal Assistant"
        class="w-[40%]"
      />
    </div>
  </div>
</template>
