<!-- eslint-disable import/no-dynamic-require -->
<script lang="ts" setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
  service: {
    type: String,
    required: true,
  },
  reverse: {
    type: Boolean,
    required: false,
  },
});

const currentUrl = new URL(import.meta.url);

const template: { [key: string]: { title: string; body: string; iconName: string } } = {
  cleaning: {
    title: 'Cleaning Services',
    body: 'Enjoy hassle-free cleaning. From laundry to deep cleaning, we keep your space spotless.',
    iconName: 'cleaning-service-icon.png',
  },
  rental: {
    title: 'Apartments Rental',
    body: 'Find the perfect student apartment in London quickly and easily with TALAB.',
    iconName: 'apartment-service-icon.png',
  },
  university: {
    title: 'University Assistance',
    body: 'Get help with your academic needs, from tutoring to administrative support.',
    iconName: 'university-service-icon.png',
  },
  booking: {
    title: 'Clubs and Bars Booking',
    body: 'Book the best clubs and bars in London and enjoy exclusive deals.',
    iconName: 'club-service-icon.png',
  },
  chauffeur: {
    title: 'Chauffeur Services',
    body: 'Ride comfortably and safely with our professional chauffeur services.',
    iconName: 'driver-service-icon.png',
  },
  personal: {
    title: 'Personal Services',
    body: 'Personal assistants ready to help with errands, appointments, and more.',
    iconName: 'private-service-icon.png',
  },
};

function getImagePath(path: string) {
  // eslint-disable-next-line global-require
  return require(`@/assets/${path}`);
}
</script>

<template>
  <div class="flex items-center gap-5 lg:flex-row" :class="{'flex-row-reverse' : !reverse}">
    <img v-if="reverse" :src="getImagePath(template[service].iconName)" class="size-16" alt="" />
    <div class="w-80"  :class="{ 'text-left': reverse, 'text-left md:text-right' : !reverse }">
      <h3 class="font-medium text-2xl">{{ template[service].title }}</h3>
      <p class="text-sm text-[#5f6980]">
        {{ template[service].body }}
      </p>
    </div>
    <img v-if="!reverse" :src="getImagePath(template[service].iconName)" class="size-16" alt="" />
  </div>
</template>
