import {
  collection, getDocs, limit, orderBy, query, Timestamp, updateDoc, where,
} from 'firebase/firestore';

import { db } from '@/config/firebase';

class ServiceService {
  static async getAll() {
    const querySnapshot = await getDocs(query(collection(db, 'services'), orderBy('category')));
    const services: any = [];
    let index = 1;

    querySnapshot.forEach((doc: any) => {
      // eslint-disable-next-line no-plusplus
      services.push({ id: index++, ...doc.data() });
    });
    return services;
  }
}

export default ServiceService;
