<template>
  <div class="bg-white py-24">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <h2 class="text-center text-4xl font-bold leading-8 text-gray-900 mb-28 anim slide-up">
        Trusted by the UK's most leading companies
      </h2>
      <div
        class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5 anim slide-left"
      >
        <img
          class="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
          src="@/assets/amber-logo.png"
          alt="Transistor"
        />
        <img
          class="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
          src="@/assets/johnsco-logo.png"
          alt="Reform"
        />
        <img
          class="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
          src="@/assets/bagatelle-logo.png"
          alt="Tuple"
        />
        <img
          class="col-span-2 max-h-16 w-full object-contain sm:col-start-2 lg:col-span-1"
          src="@/assets/maddox-logo.png"
          alt="SavvyCal"
        />
        <img
          class="col-span-2 col-start-2 max-h-16 w-full object-contain sm:col-start-auto lg:col-span-1"
          src="@/assets/cuckoo-logo.png"
          alt="Statamic"
        />
      </div>
    </div>
  </div>
</template>
