import {
  collection, deleteDoc, doc, getDocs, limit, orderBy, query, Timestamp, updateDoc, where,
} from 'firebase/firestore';

import { db } from '@/config/firebase';
import { deleteObject, getStorage, ref } from 'firebase/storage';

class EventService {
  static async getAll() {
    const querySnapshot = await getDocs(query(collection(db, 'events')));
    const events: any = [];
    let index = 1;

    querySnapshot.forEach((document: any) => {
      // eslint-disable-next-line no-plusplus
      events.push({ id: index++, ...document.data(), docId: document.id });
    });
    return events;
  }

  static async delete(eventDoc: any): Promise<void> {
    if (!eventDoc || !eventDoc.docId) {
      console.error('Invalid event document provided.');
      return;
    }

    try {
      const { docId, coverImg, images } = eventDoc; // Destructure necessary fields from the document

      // Delete the Firestore document
      const eventDocRef = doc(db, 'events', docId);
      await deleteDoc(eventDocRef);

      // Delete files from storage
      if (coverImg) {
        await this.deleteFileFromStorage(coverImg);
      }

      if (Array.isArray(images) && images.length > 0) {
        await Promise.all(images.map((imageUrl) => this.deleteFileFromStorage(imageUrl)));
      }
    } catch (error) {
      alert(`Error deleting event with ID ${eventDoc.docId}: ${error}`);
    }
  }

  // Helper function to delete a file from Firebase Storage based on its URL
  static async deleteFileFromStorage(fileUrl: string): Promise<void> {
    if (!fileUrl) return;

    try {
      const storage = getStorage();
      const decodedUrl = decodeURIComponent(fileUrl); // Decode the URL to handle special characters
      const baseUrl = 'https://firebasestorage.googleapis.com/v0/b/talab-student-app.appspot.com/o/';

      // Extract the file path from the URL
      const filePath = decodedUrl.split(baseUrl)[1]?.split('?')[0]; // Get the part after the base URL and before the query params

      if (filePath) {
        const fileRef = ref(storage, filePath); // Create a reference to the file
        await deleteObject(fileRef); // Delete the file
        console.log(`File at ${filePath} deleted successfully.`);
      }
    } catch (error) {
      console.error('Error deleting file from storage:', error);
    }
  }
}

export default EventService;
