<template>
  <div class="privacy-policy">
    <h1>PRIVACY POLICY</h1>
    <p>Last updated May 28, 2024</p>

    <p>
      This privacy notice for TALAB LTD. (doing business as TALAB) ('we', 'us', or 'our'), describes
      how and why we might collect, store, use, and/or share ('process') your information when you
      use our services ('Services'), such as when you:
    </p>
    <ul>
      <li>
        Download and use our mobile application (TALAB), or any other application of ours that links
        to this privacy notice
      </li>
      <li>Engage with us in other related ways, including any sales, marketing, or events</li>
    </ul>
    <p>
      Questions or concerns? Reading this privacy notice will help you understand your privacy
      rights and choices. If you do not agree with our policies and practices, please do not use our
      Services. If you still have any questions or concerns, please contact us at
      <a href="mailto:contact@talabapp.co.uk">contact@talabapp.co.uk</a>.
    </p>

    <h2>SUMMARY OF KEY POINTS</h2>
    <p>
      This summary provides key points from our privacy notice, but you can find out more details
      about any of these topics by clicking the link following each key point or by using our table
      of contents below to find the section you are looking for.
    </p>
    <ul>
      <li>
        <strong>What personal information do we process?</strong> When you visit, use, or navigate
        our Services, we may process personal information depending on how you interact with us and
        the Services, the choices you make, and the products and features you use.
      </li>
      <li>
        <strong>Do we process any sensitive personal information?</strong> We do not process
        sensitive personal information.
      </li>
      <li>
        <strong>Do we collect any information from third parties?</strong> We do not collect any
        information from third parties.
      </li>
      <li>
        <strong>How do we process your information?</strong> We process your information to provide,
        improve, and administer our Services, communicate with you, for security and fraud
        prevention, and to comply with law. We may also process your information for other purposes
        with your consent. We process your information only when we have a valid legal reason to do
        so.
      </li>
      <li>
        <strong>In what situations and with which parties do we share personal information?</strong>
        We may share information in specific situations and with specific third parties.
      </li>
      <li>
        <strong>How do we keep your information safe?</strong> We have organisational and technical
        processes and procedures in place to protect your personal information. However, no
        electronic transmission over the internet or information storage technology can be
        guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
        cybercriminals, or other unauthorised third parties will not be able to defeat our security
        and improperly collect, access, steal, or modify your information.
      </li>
      <li>
        <strong>What are your rights?</strong> Depending on where you are located geographically,
        the applicable privacy law may mean you have certain rights regarding your personal
        information.
      </li>
      <li>
        <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is
        by visiting <a href="https://talabapp.co.uk/">our website</a>, or by contacting us.
      </li>
    </ul>
    <p>
      Want to learn more about what we do with any information we collect? Review the privacy notice
      in full.
    </p>

    <h2>TABLE OF CONTENTS</h2>
    <ol>
      <li>WHAT INFORMATION DO WE COLLECT?</li>
      <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
      <li>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</li>
      <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
      <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
      <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
      <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
      <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
      <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
      <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
      <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
      <li>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</li>
    </ol>

    <h3>1. WHAT INFORMATION DO WE COLLECT?</h3>
    <h4>Personal information you disclose to us</h4>
    <p><strong>In Short:</strong> We collect personal information that you provide to us.</p>
    <p>
      We collect personal information that you voluntarily provide to us when you register on the
      Services, express an interest in obtaining information about us or our products and Services,
      when you participate in activities on the Services, or otherwise when you contact us.
    </p>
    <p>
      <strong>Personal Information Provided by You:</strong> The personal information that we
      collect depends on the context of your interactions with us and the Services, the choices you
      make, and the products and features you use. The personal information we collect may include
      the following:
    </p>
    <ul>
      <li>names</li>
      <li>phone numbers</li>
      <li>email addresses</li>
      <li>passwords</li>
      <li>debit/credit card numbers</li>
      <li>university name</li>
    </ul>
    <p><strong>Sensitive Information:</strong> We do not process sensitive information.</p>
    <p>
      <strong>Payment Data:</strong> We may collect data necessary to process your payment if you
      choose to make purchases, such as your payment instrument number, and the security code
      associated with your payment instrument. All payment data is handled and stored by Stripe. You
      may find their privacy notice link(s) here:
      <a href="https://stripe.com/gb/privacy">https://stripe.com/gb/privacy</a>.
    </p>
    <p>
      <strong>Application Data:</strong> If you use our application(s), we also may collect the
      following information if you choose to provide us with access or permission:
    </p>
    <ul>
      <li>
        <strong>Mobile Device Access:</strong> We may request access or permission to certain
        features from your mobile device, including your mobile device's camera, and other features.
        If you wish to change our access or permissions, you may do so in your device's settings.
      </li>
      <li>
        <strong>Mobile Device Data:</strong> We automatically collect device information (such as
        your mobile device ID, model, and manufacturer), operating system, version information and
        system configuration information, device and application identification numbers, browser
        type and version, hardware model Internet service provider and/or mobile carrier, and
        Internet Protocol (IP) address (or proxy server). If you are using our application(s), we
        may also collect information about the phone network associated with your mobile device,
        your mobile device’s operating system or platform, the type of mobile device you use, your
        mobile device’s unique device ID, and information about the features of our application(s)
        you accessed.
      </li>
      <li>
        <strong>Push Notifications:</strong> We may request to send you push notifications regarding
        your account or certain features of the application(s). If you wish to opt out from
        receiving these types of communications, you may turn them off in your device's settings.
      </li>
    </ul>
    <p>
      This information is primarily needed to maintain the security and operation of our
      application(s), for troubleshooting, and for our internal analytics and reporting purposes.
    </p>
    <p>
      All personal information that you provide to us must be true, complete, and accurate, and you
      must notify us of any changes to such personal information.
    </p>

    <h3>2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
    <p>
      <strong>In Short:</strong> We process your information to provide, improve, and administer our
      Services, communicate with you, for security and fraud prevention, and to comply with law. We
      may also process your information for other purposes with your consent.
    </p>
    <p>
      We process your personal information for a variety of reasons, depending on how you interact
      with our Services, including:
    </p>
    <ul>
      <li>
        <strong
          >To facilitate account creation and authentication and otherwise manage user
          accounts.</strong
        >
        We may process your information so you can create and log in to your account, as well as
        keep your account in working order.
      </li>
      <li>
        <strong>To deliver and facilitate delivery of services to the user.</strong> We may process
        your information to provide you with the requested service.
      </li>
      <li>
        <strong>To respond to user inquiries/offer support to users.</strong> We may process your
        information to respond to your inquiries and solve any potential issues you might have with
        the requested service.
      </li>
      <li>
        <strong>To fulfil and manage your orders.</strong> We may process your information to fulfil
        and manage your orders, payments, returns, and exchanges made through the Services.
      </li>
      <li>
        <strong>To save or protect an individual's vital interest.</strong> We may process your
        information when necessary to save or protect an individual’s vital interest, such as to
        prevent harm.
      </li>
    </ul>

    <h3>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h3>
    <p>
      <strong>In Short:</strong> We only process your personal information when we believe it is
      necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law,
      like with your consent, to comply with laws, to provide you with services to enter into or
      fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate
      business interests.
    </p>
    <p>
      The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid
      legal bases we rely on in order to process your personal information. As such, we may rely on
      the following legal bases to process your personal information:
    </p>
    <ul>
      <li>
        <strong>Consent:</strong> We may process your information if you have given us permission
        (i.e. consent) to use your personal information for a specific purpose. You can withdraw
        your consent at any time.
      </li>
      <li>
        <strong>Performance of a Contract:</strong> We may process your personal information when we
        believe it is necessary to fulfil our contractual obligations to you, including providing
        our Services or at your request prior to entering into a contract with you.
      </li>
      <li>
        <strong>Legal Obligations:</strong> We may process your information where we believe it is
        necessary for compliance with our legal obligations, such as to cooperate with a law
        enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your
        information as evidence in litigation in which we are involved.
      </li>
      <li>
        <strong>Vital Interests:</strong> We may process your information where we believe it is
        necessary to protect your vital interests or the vital interests of a third party, such as
        situations involving potential threats to the safety of any person.
      </li>
    </ul>
    <p>
      In legal terms, we are generally the 'data controller' under European data protection laws of
      the personal information described in this privacy notice, since we determine the means and/or
      purposes of the data processing we perform. This privacy notice does not apply to the personal
      information we process as a 'data processor' on behalf of our customers. In those situations,
      the customer that we provide services to and with whom we have entered into a data processing
      agreement is the 'data controller' responsible for your personal information, and we merely
      process your information on their behalf in accordance with your instructions. If you want to
      know more about our customers' privacy practices, you should read their privacy policies and
      direct any questions you have to them.
    </p>

    <h3>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h3>
    <p>
      <strong>In Short:</strong> We may share information in specific situations described in this
      section and/or with the following third parties.
    </p>
    <p>We may need to share your personal information in the following situations:</p>
    <ul>
      <li>
        <strong>Business Transfers:</strong> We may share or transfer your information in connection
        with, or during negotiations of, any merger, sale of company assets, financing, or
        acquisition of all or a portion of our business to another company.
      </li>
      <li>
        <strong>Business Partners:</strong> We may share your information with our business partners
        to offer you certain products, services, or promotions.
      </li>
    </ul>

    <h3>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
    <p>
      <strong>In Short:</strong> We keep your information for as long as necessary to fulfil the
      purposes outlined in this privacy notice unless otherwise required by law.
    </p>
    <p>
      We will only keep your personal information for as long as it is necessary for the purposes
      set out in this privacy notice, unless a longer retention period is required or permitted by
      law (such as tax, accounting, or other legal requirements). No purpose in this notice will
      require us keeping your personal information for longer than three (3) months past the start
      of the idle or deletion period of the user's account.
    </p>
    <p>
      When we have no ongoing legitimate business need to process your personal information, we will
      either delete or anonymise such information, or, if this is not possible (for example, because
      your personal information has been stored in backup archives), then we will securely store
      your personal information and isolate it from any further processing until deletion is
      possible.
    </p>

    <h3>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
    <p>
      <strong>In Short:</strong> We aim to protect your personal information through a system of
      organisational and technical security measures.
    </p>
    <p>
      We have implemented appropriate and reasonable technical and organisational security measures
      designed to protect the security of any personal information we process. However, despite our
      safeguards and efforts to secure your information, no electronic transmission over the
      Internet or information storage technology can be guaranteed to be 100% secure, so we cannot
      promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will
      not be able to defeat our security and improperly collect, access, steal, or modify your
      information. Although we will do our best to protect your personal information, transmission
      of personal information to and from our Services is at your own risk. You should only access
      the Services within a secure environment.
    </p>

    <h3>7. DO WE COLLECT INFORMATION FROM MINORS?</h3>
    <p>
      <strong>In Short:</strong> We do not knowingly collect data from or market to children under
      18 years of age.
    </p>
    <p>
      We do not knowingly collect, solicit data from, or market to children under 18 years of age,
      nor do we knowingly sell such personal information. By using the Services, you represent that
      you are at least 18 or that you are the parent or guardian of such a minor and consent to such
      minor dependent’s use of the Services. If we learn that personal information from users less
      than 18 years of age has been collected, we will deactivate the account and take reasonable
      measures to promptly delete such data from our records. If you become aware of any data we may
      have collected from children under age 18, please contact us at
      <a href="mailto:contact@talabapp.co.uk">contact@talabapp.co.uk</a>.
    </p>

    <h3>8. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
    <p>
      <strong>In Short:</strong> In some regions, such as the European Economic Area (EEA), United
      Kingdom (UK), and Switzerland, you have rights that allow you greater access to and control
      over your personal information. You may review, change, or terminate your account at any time,
      depending on your country, province, or state of residence.
    </p>
    <p>
      In some regions (like the EEA, UK, and Switzerland), you have certain rights under applicable
      data protection laws. These may include the right (i) to request access and obtain a copy of
      your personal information, (ii) to request rectification or erasure; (iii) to restrict the
      processing of your personal information; (iv) if applicable, to data portability; and (v) not
      to be subject to automated decision-making. In certain circumstances, you may also have the
      right to object to the processing of your personal information. You can make such a request by
      contacting us by using the contact details provided in the section 'HOW CAN YOU CONTACT US
      ABOUT THIS NOTICE?' below.
    </p>
    <p>
      We will consider and act upon any request in accordance with applicable data protection laws.
    </p>
    <p>
      If you are located in the EEA or UK and you believe we are unlawfully processing your personal
      information, you also have the right to complain to your Member State data protection
      authority or UK data protection authority.
    </p>
    <p>
      If you are located in Switzerland, you may contact the Federal Data Protection and Information
      Commissioner.
    </p>
    <p>
      <strong>Withdrawing your consent:</strong> If we are relying on your consent to process your
      personal information, you have the right to withdraw your consent at any time. You can
      withdraw your consent at any time by contacting us by using the contact details provided in
      the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.
    </p>
    <p>
      However, please note that this will not affect the lawfulness of the processing before its
      withdrawal nor, will it affect the processing of your personal information conducted in
      reliance on lawful processing grounds other than consent.
    </p>
    <p>
      <strong>Opting out of marketing and promotional communications:</strong>
      You can unsubscribe from our marketing and promotional communications at any time by clicking
      on the unsubscribe link in the emails that we send, replying 'STOP' or 'UNSUBSCRIBE' to the
      SMS messages that we send, or by contacting us using the details provided in the section 'HOW
      CAN YOU CONTACT US ABOUT THIS NOTICE?' below. You will then be removed from the marketing
      lists. However, we may still communicate with you — for example, to send you service-related
      messages that are necessary for the administration and use of your account, to respond to
      service requests, or for other non-marketing purposes.
    </p>

    <h4>Account Information</h4>
    <p>
      If you would at any time like to review or change the information in your account or terminate
      your account, you can:
    </p>
    <ul>
      <li>Log in to your account settings and update your user account.</li>
      <li>Contact us using the contact information provided.</li>
    </ul>
    <p>
      Upon your request to terminate your account, we will deactivate or delete your account and
      information from our active databases. However, we may retain some information in our files to
      prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms
      and/or comply with applicable legal requirements.
    </p>
    <p>
      If you have questions or comments about your privacy rights, you may email us at
      <a href="mailto:contact@talabapp.co.uk">contact@talabapp.co.uk</a>.
    </p>

    <h3>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
    <p>
      Most web browsers and some mobile operating systems and mobile applications include a
      Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not
      to have data about your online browsing activities monitored and collected. At this stage, no
      uniform technology standard for recognising and implementing DNT signals has been finalised.
      As such, we do not currently respond to DNT browser signals or any other mechanism that
      automatically communicates your choice not to be tracked online. If a standard for online
      tracking is adopted that we must follow in the future, we will inform you about that practice
      in a revised version of this privacy notice.
    </p>

    <h3>10. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
    <p>
      <strong>In Short:</strong> Yes, we will update this notice as necessary to stay compliant with
      relevant laws.
    </p>
    <p>
      We may update this privacy notice from time to time. The updated version will be indicated by
      an updated 'Revised' date at the top of this privacy notice. If we make material changes to
      this privacy notice, we may notify you either by prominently posting a notice of such changes
      or by directly sending you a notification. We encourage you to review this privacy notice
      frequently to be informed of how we are protecting your information.
    </p>

    <h3>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
    <p>
      If you have questions or comments about this notice, you may contact our Data Protection
      Officer (DPO) by email at
      <a href="mailto:contact@talabapp.co.uk">contact@talabapp.co.uk</a>, by phone at +44 7823
      938827, or contact us by post at:
    </p>
    <p>
      TALAB LTD.<br />
      Data Protection Officer<br />
      D31 Legacy Building, 1 Viaduct Gardens<br />
      London SW11 7DZ<br />
      England
    </p>
    <p>
      If you are a resident in the United Kingdom, we are the 'data controller' of your personal
      information. We have appointed Mirkazim Seyidzade to be our representative in the UK. You can
      contact them directly regarding our processing of your information, by email at
      <a href="mailto:contact@talabapp.co.uk">contact@talabapp.co.uk</a>, by visiting
      <a href="https://talabapp.co.uk/">our website</a>, by phone at +44 7823 938827, or by post to:
    </p>
    <p>
      D31 Legacy Building, 1 Viaduct Gardens<br />
      London SW11 7DZ<br />
      England
    </p>

    <h3>12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h3>
    <p>
      Based on the applicable laws of your country, you may have the right to request access to the
      personal information we collect from you, details about how we have processed it, correct
      inaccuracies, or delete your personal information. You may also have the right to withdraw
      your consent to our processing of your personal information. These rights may be limited in
      some circumstances by applicable law. To request to review personal information contact at
      <a href="mailto:contact@talabapp.co.uk">contact@talabapp.co.uk</a>. To update, or delete your
      personal information, please visit profile page in TALAB app..
    </p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicyView',
};
</script>

<style scoped>
.privacy-policy {
  font-family: "Inter", sans-serif;
  line-height: 1.6;
  color: #333;
  padding: 20px;
  text-align: left;
}

.privacy-policy h1,
.privacy-policy h2,
.privacy-policy h3,
.privacy-policy h4 {
  color: #bc8809;
  margin-top: 2rem;
}

.privacy-policy a {
  color: #0056b3;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}
</style>
