<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import EventService from '@/service/event.service';
import EventModal from '@/components/admin-dashboard/EventModal.vue';
import LoadingModal from '@/components/admin-dashboard/LoadingModal.vue';
import ConfirmationModal from '@/components/admin-dashboard/ConfirmationModal.vue';

const router = useRouter();

const events = ref<[any]>();

const showModal = ref(false);

const isLoading = ref(false);

const showConfirmation = ref(false);

const eventForDeletion = ref(null);

onMounted(async () => {
  if (!localStorage.getItem('loggedIn')) {
    router.push({ name: 'admin-login' });
    return;
  }
  try {
    events.value = await EventService.getAll();
  } catch (error) {
    console.error(error);
  }
});

function deleteEvent(event: any) {
  if (isLoading.value) return;
  eventForDeletion.value = event;
  showConfirmation.value = true;
}

async function confirmDeletion() {
  showConfirmation.value = false;
  isLoading.value = true;
  console.log(eventForDeletion.value);
  await EventService.delete(eventForDeletion.value);
  events.value = await EventService.getAll();
  isLoading.value = false;
  alert('Event and its associated images deleted successfully.');
}
</script>
<template>
  <div class="flex flex-col p-5">
    <div class="h-screen">
      <div class="flex-1 flex items-center justify-center mb-5">
        <img
          @click="router.push({name: 'admin-dashboard'})"
          src="@/assets/gold-logo-transparent.png"
          alt="TALAB - Ultimate Student App in London Logo"
          class="w-32 mx-auto cursor-pointer"
        />
        <div
          @click="showModal = true"
          className="px-3 py-3 text-indigo-600 bg-indigo-50 rounded-lg duration-150 hover:bg-indigo-100 active:bg-indigo-200 flex items-center cursor-pointer"
        >
          <p className="mr-1">Create Event</p>
          <svg
            class="w-6 h-6"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 12h14m-7 7V5"
            />
          </svg>
        </div>
      </div>

      <div
        class="block max-w-xs p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
      >
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {{ events?.length ?? "Loading..." }}
        </h5>
        <p class="font-normal text-gray-700 dark:text-gray-400">events</p>
      </div>
      <h2 class="text-center text-3xl font-medium my-5">Current events</h2>
      <div class="w-full flex flex-row flex-wrap gap-5">
        <div
          v-for="event in events"
          :key="event.id"
          class="w-4/12 border-2 rounded-lg overflow-hidden relative"
        >
          <button
            type="button"
            class="text-white bg-red-600 hover:bg-red-800 focus:ring-1 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm p-2 text-center inline-flex items-center me-2 absolute right-2 top-2"
            @click="deleteEvent(event)"
          >
            <svg
              class="w-[16px] h-[16px] text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.3"
                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
              />
            </svg>
          </button>

          <img :src="event.coverImg" alt="Cover Image" class="object-cover h-64 w-full" />
          <div class="p-2">
            <!-- <p class="text-sm">{{ event.category }}</p> -->
            <p class="font-semibold text-lg">{{ event.title }}</p>
            <p class="font-normal mb-2">{{ event.subTitle }}</p>
            <p class="text-gray-400">{{ event.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EventModal v-if="showModal" @close="showModal = false" />
  <LoadingModal v-if="isLoading"/>
  <ConfirmationModal v-if="showConfirmation" @cancel="showConfirmation = false" @confirm="confirmDeletion"/>
</template>
