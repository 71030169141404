<template>
  <div class="text-gray-600 py-24" id="reviews">
    <div class="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
      <div class="mb-10 space-y-4 px-6 md:px-0">
        <h2 class="text-center font-bold tracking-tight text-gray-900 text-4xl anim slide-up">
          We have helped hundreds <br> of amazing students
        </h2>
      </div>

      <div class="md:columns-2 lg:columns-3 gap-8 space-y-8">
        <div
          class="aspect-auto p-8 border border-gray-100 rounded-3xl bg-white shadow-2xl shadow-gray-600/10 anim"
        >
          <div class="flex gap-4">
            <img
              class="w-12 h-12 rounded-full"
              src="@/assets/reviewer-1-pic.jpg"
              alt="user avatar"
              width="400"
              height="400"
              loading="lazy"
            />
            <div>
              <h6 class="text-lg font-medium text-gray-700">@C 🩷</h6>
              <p class="text-sm text-gray-500">Outstanding!!</p>
            </div>
          </div>
          <p class="mt-8">
            The app has a very nice layout and is easy to use. Makes everything quicker whenever I want to make any plans out in London or need help with all sorts of tasks. Very glad an app like this came out specifically for international students in London 😊
          </p>
        </div>

        <div
          class="aspect-auto p-8 border border-gray-100 rounded-3xl bg-white shadow-2xl shadow-gray-600/10 anim delay-1"
        >
          <div class="flex gap-4">
            <img
              class="w-12 h-12 rounded-full"
              src="@/assets/reviewer-4-pic.jpg"
              alt="user avatar"
              width="400"
              height="400"
              loading="lazy"
            />
            <div>
              <h6 class="text-lg font-medium text-gray-700">@geogddhzgsh</h6>
              <p class="text-sm text-gray-500">THE BEST APP</p>
            </div>
          </div>
          <p class="mt-8">
            Made my life as a London student much easier. They booked an amazing student apartment for me for the summer. Honestly I was struggling with it but they helped me so quick. Definitely worth trying🔝
          </p>
        </div>

        <div
          class="aspect-auto p-8 border border-gray-100 rounded-3xl bg-white shadow-2xl shadow-gray-600/10 anim delay-2"
        >
        <div class="flex gap-4">
            <img
              class="w-10 h-10 rounded-full"
              src="@/assets/user-profile-icon.png"
              alt="user avatar"
              width="150"
              height="150"
              loading="lazy"
            />
            <div>
              <h6 class="text-lg font-medium text-gray-700">@Arthurali</h6>
              <p class="text-sm text-gray-500">Best app for living in London</p>
            </div>
          </div>
          <p class="mt-8">
            Using the TALAB App has been a game-changer for me. Not only does it provide a seamless experience for all my needs, but the personal assistance's quick response has truly been a life-saver. Whether it's booking a table at my favorite restaurant or arranging transportation, the team behind TALAB is always there to help promptly.
          </p>
        </div>

        <div
          class="aspect-auto p-8 border border-gray-100 rounded-3xl bg-white shadow-2xl shadow-gray-600/10 anim delay-3"
        >
          <div class="flex gap-4">
            <img
              class="w-12 h-12 rounded-full"
              src="@/assets/reviewer-2-pic.png"
              alt="user avatar"
              width="200"
              height="200"
              loading="lazy"
            />
            <div>
              <h6 class="text-lg font-medium text-gray-700">Peyton Reid</h6>
              <p class="text-sm text-gray-500">Very convenient app</p>
            </div>
          </div>
          <p class="mt-8">
            TALAB App has truly transformed my student life in London. The standout feature for me is the personal assistance. Whenever I make a request, I receive a response almost instantly, and the support team is incredibly helpful and efficient.
          </p>
        </div>

        <div
          class="aspect-auto p-8 border border-gray-100 rounded-3xl bg-white 0 shadow-2xl shadow-gray-600/10 anim delay-4"
        >
          <div class="flex gap-4">
            <img
              class="w-12 h-12 rounded-full"
              src="@/assets/reviewer-3-pic.jpg"
              alt="user avatar"
              width="200"
              height="200"
              loading="lazy"
            />
            <div>
              <h6 class="text-lg font-medium text-gray-700">Roberta Ellis</h6>
              <p class="text-sm text-gray-500">Life-saver</p>
            </div>
          </div>
          <p class="mt-8">
            The TALAB App is a fantastic tool for students, offering comprehensive services like accommodation and transportation. It has made my student life in the UK so much easier and more organized!
          </p>
        </div>

        <div
          class="aspect-auto p-8 border border-gray-100 rounded-3xl bg-white shadow-2xl shadow-gray-600/10 anim delay-5"
        >
        <div class="flex gap-4">
            <img
              class="w-10 h-10 rounded-full"
              src="@/assets/user-profile-icon.png"
              alt="user avatar"
              width="150"
              height="150"
              loading="lazy"
            />
            <div>
              <h6 class="text-lg font-medium text-gray-700">@KeremCelik47</h6>
              <p class="text-sm text-gray-500">Essential App for Students</p>
            </div>
          </div>
          <p class="mt-8">
            If you're in London and need help with anything, Talab is the best app for it. I really enjoyed using it and found it incredibly easy to find what I needed and get things done. Talab handles everything for you. Everyone should give it a go!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
