<!-- eslint-disable no-restricted-syntax -->
<!-- eslint-disable no-await-in-loop -->
<script setup lang="ts">
import { ref, defineEmits, computed } from 'vue';
import { uploadBytes, getDownloadURL, ref as storageRef } from 'firebase/storage';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { db, storage } from '@/config/firebase';

const emit = defineEmits(['close']);

interface EventModel {
  title: string;
  subTitle: string;
  description: string;
  coverImg: string;
  images: string[];
  location: string;
  dateTime: Timestamp | boolean;
}

const event = ref<EventModel>({
  title: '',
  subTitle: '',
  description: '',
  coverImg: '',
  images: [],
  location: '',
  dateTime: false,
});

const dateTimeInput = ref<string>('');
const coverImgFile = ref<File | null>(null);
const imageFiles = ref<File[]>([]);

const coverImgUrl = computed(() => (coverImgFile.value ? URL.createObjectURL(coverImgFile.value) : null));

// Computed property for multiple image URLs
const imageUrls = computed(() => imageFiles.value.map((file) => URL.createObjectURL(file)));

function onFileChange(e: Event, type: string) {
  const target = e.target as HTMLInputElement;
  if (type === 'coverImg' && target.files) {
    const [targetFile, ...rest] = target.files;
    coverImgFile.value = targetFile;
  } else if (type === 'images' && target.files) {
    imageFiles.value = Array.from(target.files);
  }
}

function resizeImage(file: File): Promise<File> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    img.onload = () => {
      const aspectRatio = img.width / img.height;
      if (img.width > 1250) {
        canvas.width = 1250;
        canvas.height = 1250 / aspectRatio;
      } else {
        canvas.width = img.width;
        canvas.height = img.height;
      }

      if (ctx) {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          if (blob) {
            const resizedFile = new File([blob], file.name, { type: file.type });
            resolve(resizedFile);
          } else {
            reject(new Error('Canvas to Blob conversion failed'));
          }
        }, file.type);
      } else {
        reject(new Error('Canvas context is not available'));
      }
    };

    img.onerror = (error) => reject(error);
    img.src = URL.createObjectURL(file);
  });
}

async function uploadFile(file: File): Promise<string> {
  const resizedFile = await resizeImage(file);
  const fileRef = storageRef(storage, `events/${resizedFile.name}`);
  const snapshot = await uploadBytes(fileRef, resizedFile);
  return getDownloadURL(snapshot.ref);
}

async function addEvent() {
  try {
    if (coverImgFile.value) {
      event.value.coverImg = await uploadFile(coverImgFile.value);
    }

    if (imageFiles.value.length > 0) {
      for (const file of imageFiles.value) {
        const url = await uploadFile(file);
        event.value.images.push(url);
      }
    }

    if (dateTimeInput.value) {
      event.value.dateTime = Timestamp.fromDate(new Date(dateTimeInput.value));
    } else {
      event.value.dateTime = false;
    }

    await addDoc(collection(db, 'events'), event.value);

    alert('Event added successfully!');
    // Reset form
    event.value = {
      title: '',
      subTitle: '',
      description: '',
      coverImg: '',
      images: [],
      location: '',
      dateTime: false,
    };
    dateTimeInput.value = '';
    coverImgFile.value = null;
    imageFiles.value = [];
    emit('close');
  } catch (error) {
    console.error('Error adding event: ', error);
  }
}
</script>

<template>
  <div class="fixed inset-0 flex justify-center bg-gray-500 bg-opacity-50">
    <div
      id="crud-modal"
      tabindex="-1"
      class="overflow-y-auto overflow-x-hidden justify-center items-center"
    >
      <div class="relative p-4 w-[40vw] max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <!-- Modal header -->
          <div
            class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600"
          >
            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Add New Event</h3>
            <button
              type="button"
              @click="$emit('close')"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="crud-modal"
            >
              <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-4 md:p-5">
            <div class="grid gap-4 mb-4 grid-cols-2">
              <div class="col-span-2">
                <label
                  for="title"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Title</label
                >
                <input
                  v-model="event.title"
                  type="text"
                  name="title"
                  id="title"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Type event title"
                />
              </div>
              <div class="col-span-2">
                <label
                  for="subTitle"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >SubTitle</label
                >
                <input
                  v-model="event.subTitle"
                  type="text"
                  name="subTitle"
                  id="subTitle"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Type event subTitle"
                />
              </div>
              <div class="col-span-2">
                <label
                  for="location"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Location</label
                >
                <input
                  v-model="event.location"
                  type="text"
                  name="location"
                  id="location"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Type event location"
                />
              </div>
              <div class="col-span-2">
                <label
                  for="dateTime"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Date and Time</label
                >
                <input
                  v-model="dateTimeInput"
                  type="datetime-local"
                  name="dateTime"
                  id="dateTime"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Select date and time"
                />
              </div>
              <div class="col-span-2">
                <label
                  for="description"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Description</label
                >
                <textarea
                  v-model="event.description"
                  id="description"
                  rows="4"
                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write event description here"
                ></textarea>
              </div>
            </div>
            <div class="flex items-center justify-center w-full mb-3">
              <label
                for="coverImg"
                class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span class="font-semibold">Click to upload cover image</span>
                  </p>
                  <p class="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG or JPG
                  </p>
                </div>
                <input
                  @change="onFileChange($event, 'coverImg')"
                  id="coverImg"
                  type="file"
                  class="hidden"
                />
              </label>
            </div>
            <div class="flex w-full mb-3" v-if="coverImgUrl">
              <div >
                <img :src="coverImgUrl" alt="Cover Image" class="w-60 rounded-lg" />
              </div>
            </div>
            <div class="flex items-center justify-center w-full mb-3">
              <label
                for="images"
                class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span class="font-semibold">Click to upload additional images</span>
                  </p>
                  <p class="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG or JPG
                  </p>
                </div>
                <input
                  @change="onFileChange($event, 'images')"
                  id="images"
                  type="file"
                  class="hidden"
                  multiple
                />
              </label>
            </div>
            <div class="flex w-full gap-3 overflow-scroll mb-3" v-if="imageUrls.length > 0">
                <img v-for="(image, index) in imageUrls" :key="index" :src="image" alt="Cover Image" class="w-60 object-cover rounded-lg" />
            </div>
            <button
              @click="addEvent"
              class="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
            >
              <span
                class="relative inline-flex items-center px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
              >
                <svg
                  class="me-1 -ms-1 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Add new event
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
