<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { generateCsv } from '@/helpers';
import ServiceModal from '@/components/ServiceModal.vue';
import { useRouter } from 'vue-router';
import ServiceService from '@/service/service.service';

const router = useRouter();

const services = ref<[any]>();

const showModal = ref(false);

onMounted(async () => {
  if (!localStorage.getItem('loggedIn')) {
    router.push({ name: 'admin-login' });
    return;
  }
  try {
    services.value = await ServiceService.getAll();
  } catch (error) {
    console.error(error);
  }
});
</script>
<template>
  <div class="flex flex-col p-5">
    <div class="h-screen">
      <div class="flex-1 flex items-center justify-center mb-5">
        <img
          src="@/assets/gold-logo-transparent.png"
          alt="TALAB - Ultimate Student App in London Logo"
          class="w-32 mx-auto"
        />
        <div
          @click="showModal = true"
          className="px-3 py-3 text-indigo-600 bg-indigo-50 rounded-lg duration-150 hover:bg-indigo-100 active:bg-indigo-200 flex items-center cursor-pointer"
        >
          <p className="mr-1">Add Service</p>
          <svg
            class="w-6 h-6"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 12h14m-7 7V5"
            />
          </svg>
        </div>
      </div>

      <div
        class="block max-w-xs p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
      >
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {{ services?.length ?? "Loading..." }}
        </h5>
        <p class="font-normal text-gray-700 dark:text-gray-400">Services</p>
      </div>
      <h2 class="text-center text-3xl font-medium my-5">Current Services</h2>
      <div class="w-full flex flex-row flex-wrap gap-5">
        <div
          v-for="service in services"
          :key="service.id"
          class="w-2/12 border-2 rounded-lg overflow-hidden"
        >
          <img :src="service.coverImg" alt="Cover Image" class="object-cover h-2/6 w-full" />
          <div class="p-2">
            <p class="text-sm">{{ service.category }}</p>
            <p class="font-semibold text-lg">{{ service.title }}</p>
            <p class="font-normal mb-2">{{ service.subTitle }}</p>
            <p class="text-gray-400">{{ service.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ServiceModal v-if="showModal" @close="showModal = false" />
</template>
