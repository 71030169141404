<!-- eslint-disable no-restricted-syntax -->
<!-- eslint-disable no-await-in-loop -->
<script setup lang="ts">
import { ref, defineEmits } from 'vue';
import { uploadBytes, getDownloadURL, ref as storageRef } from 'firebase/storage';
import { addDoc, collection } from 'firebase/firestore';
import { db, storage } from '@/config/firebase';

const emit = defineEmits(['close']);

interface Service {
  title: string;
  subTitle: string;
  category: string;
  description: string;
  coverImg: string;
  images: string[];
}

const service = ref<Service>({
  title: '',
  subTitle: '',
  category: '',
  description: '',
  coverImg: '',
  images: [],
});

const coverImgFile = ref<File | null>(null);
const imageFiles = ref<File[]>([]);

function onFileChange(event: Event, type: string) {
  const target = event.target as HTMLInputElement;
  if (type === 'coverImg' && target.files) {
    const [targetFile, ...rest] = target.files;
    coverImgFile.value = targetFile;
  } else if (type === 'images' && target.files) {
    imageFiles.value = Array.from(target.files);
  }
}

function resizeImage(file: File): Promise<File> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    img.onload = () => {
      const aspectRatio = img.width / img.height;
      if (img.width > 1250) {
        canvas.width = 1250;
        canvas.height = 1250 / aspectRatio;
      } else {
        canvas.width = img.width;
        canvas.height = img.height;
      }

      if (ctx) {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          if (blob) {
            const resizedFile = new File([blob], file.name, { type: file.type });
            resolve(resizedFile);
          } else {
            reject(new Error('Canvas to Blob conversion failed'));
          }
        }, file.type);
      } else {
        reject(new Error('Canvas context is not available'));
      }
    };

    img.onerror = (error) => reject(error);
    img.src = URL.createObjectURL(file);
  });
}

async function uploadFile(file: File): Promise<string> {
  const resizedFile = await resizeImage(file);
  const fileRef = storageRef(storage, `services/${resizedFile.name}`);
  const snapshot = await uploadBytes(fileRef, resizedFile);
  return getDownloadURL(snapshot.ref);
}

async function addService() {
  try {
    if (coverImgFile.value) {
      service.value.coverImg = await uploadFile(coverImgFile.value);
    }

    if (imageFiles.value.length > 0) {
      for (const file of imageFiles.value) {
        const url = await uploadFile(file);
        service.value.images.push(url);
      }
    }

    await addDoc(collection(db, 'services'), service.value);

    alert('Service added successfully!');
    // Reset form
    service.value = {
      title: '',
      subTitle: '',
      category: '',
      description: '',
      coverImg: '',
      images: [],
    };
    coverImgFile.value = null;
    imageFiles.value = [];
    emit('close');
  } catch (error) {
    console.error('Error adding service: ', error);
  }
}
</script>
<template>
  <div class="fixed inset-0 flex justify-center bg-gray-500 bg-opacity-50">
    <div
      id="crud-modal"
      tabindex="-1"
      class="overflow-y-auto overflow-x-hidden justify-center items-center"
    >
      <div class="relative p-4 w-full max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <!-- Modal header -->
          <div
            class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600"
          >
            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Add New Service</h3>
            <button
              type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="crud-modal"
              @click="emit('close')"
            >
              <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-4 md:p-5">
            <div class="grid gap-4 mb-4 grid-cols-2">
              <div class="col-span-2">
                <label
                  for="title"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Title</label
                >
                <input
                  v-model="service.title"
                  type="text"
                  name="title"
                  id="title"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Type service title"
                />
              </div>
              <div class="col-span-2">
                <label
                  for="subTitle"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >SubTitle</label
                >
                <input
                  v-model="service.subTitle"
                  type="text"
                  name="subTitle"
                  id="subTitle"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Type service subTitle"
                />
              </div>
              <div class="col-span-2 sm:col-span-1">
                <label
                  for="category"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Category</label
                >
                <input
                  v-model="service.category"
                  type="text"
                  name="category"
                  id="category"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Type service category"
                />
              </div>
              <div class="col-span-2">
                <label
                  for="description"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Description</label
                >
                <textarea
                  v-model="service.description"
                  id="description"
                  rows="4"
                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write service description here"
                ></textarea>
              </div>
            </div>
            <div class="flex items-center justify-center w-full mb-3 gap-4">
              <div class="flex items-center justify-center w-full">
                <label
                  for="coverImg"
                  class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div class="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span class="font-semibold">Click to upload cover image</span>
                    </p>
                    <p class="text-xs text-gray-500 dark:text-gray-400">
                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                    </p>
                  </div>
                  <input
                    @change="onFileChange($event, 'coverImg')"
                    id="coverImg"
                    type="file"
                    class="hidden"
                  />
                </label>
              </div>
              <div class="flex items-center justify-center w-full">
                <label
                  for="images"
                  class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div class="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span class="font-semibold">Click to upload additional images</span>
                    </p>
                    <p class="text-xs text-gray-500 dark:text-gray-400">
                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                    </p>
                  </div>
                  <input
                    @change="onFileChange($event, 'images')"
                    id="images"
                    type="file"
                    class="hidden"
                    multiple
                  />
                </label>
              </div>
            </div>
            <button
              @click="addService"
              class="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
            >
              <span
                class="relative inline-flex items-center px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
              >
                <svg
                  class="me-1 -ms-1 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Add new service
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
