import { db } from '@/config/firebase';
import {
  addDoc, collection, getDocs, getFirestore, Timestamp,
} from 'firebase/firestore';

// src/utils/generateCsv.js
// import { parse } from 'json2csv';

import { json2csv } from 'json-2-csv';

export const generateCsv = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'users'));
    const users: any = [];
    let index = 1;

    querySnapshot.forEach((doc) => {
      // eslint-disable-next-line no-plusplus
      users.push({ id: index++, ...doc.data() });
    });

    // const csv = parse(users);
    const csv = await json2csv(users);
    const blob = new Blob([csv], { type: 'text/csv' });

    // Create a link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'users.csv';

    // Append the link to the body
    document.body.appendChild(link);

    // Simulate click to download
    link.click();

    // Cleanup: remove the link
    document.body.removeChild(link);
  } catch (e) {
    console.log(e);
  }
};

// export const generateCsvFormatTime = async (collectionName = 'userbase') => {
//   try {
//     const querySnapshot = await getDocs(collection(db, collectionName));
//     const users:any = [];
//     let index = 1;

//     querySnapshot.forEach((doc) => {
//       const data = doc.data();
//       // eslint-disable-next-line no-underscore-dangle
//       const createdAt = new Date(data._createdAt.seconds * 1000 + data._createdAt.nanoseconds / 1000000);
//       users.push({
//         // eslint-disable-next-line no-plusplus
//         id: index++,
//         ...data,
//         createdAt: createdAt.toLocaleString(),
//       });
//     });

//     const csv = await json2csv(users);

//     // Create a Blob from the CSV data
//     const blob = new Blob([csv], { type: 'text/csv' });

//     // Create a link element
//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(blob);
//     link.download = 'userbase.csv';

//     // Append the link to the body
//     document.body.appendChild(link);

//     // Simulate click to download
//     link.click();

//     // Cleanup: remove the link
//     document.body.removeChild(link);
//   } catch (error) {
//     console.error('Error generating CSV: ', error);
//   }
// };

export default generateCsv;
