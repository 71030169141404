// eslint-disable-next-line import/no-extraneous-dependencies
import { initializeApp } from 'firebase/app';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

/// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyBsWmbTelo5zCLrlUjhW3iLWW2IIyvyEME',
  authDomain: 'talab-student-app.firebaseapp.com',
  projectId: 'talab-student-app',
  storageBucket: 'talab-student-app.appspot.com',
  messagingSenderId: '639647800007',
  appId: '1:639647800007:web:09a4ac65c78bdd37d43ce2',
  measurementId: 'G-18CM8CDM3B',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

// export default db;
export { db, storage };
